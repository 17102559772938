<template>
  <div id="parkingGroup">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>联合停车场</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addPassageway"
              type="primary"
              @click="actionParkingGroup(null)"
              v-if="buttonList.includes('parkingGroup-addBtn')"
              ><i class="iconfont icon-jiahao"></i> 新建</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="groupName" label="名称" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingLotAmount"
          label="停车场数量"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="idleLots" label="剩余车位" align="center">
        </el-table-column>
        <el-table-column prop="totalLots" label="总车位" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.parkingGroupId)"
              v-if="buttonList.includes('parkingGroup-detail')"
              >查看详情</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.parkingGroupId)"
              v-if="buttonList.includes('parkingGroup-delBtn')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    actionParkingGroup() {
      this.$prompt("", `新建联合停车场`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "联合停车场名称不能为空",
        inputPlaceholder: "请输入联合停车场名称",
      }).then(async ({ value }) => {
        let params = { groupName: value };
        let res = await this.$http.post(`/parking/group/add`, params);
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(parkingGroupId) {
      this.$confirm("此操作将永久删除该联合停车场, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(
            `/parking/group/${parkingGroupId}/delete`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/group/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    goToDetail(parkingGroupId) {
      this.$router.push({
        name: "parkingGroupDetail",
        params: { id: parkingGroupId },
      });
    },
  },
};
</script>
<style lang="scss">
#parkingGroup {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addPassageway {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
